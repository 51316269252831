<template>
  <div class="want-report-wrapper">
    <div class="content-wrapper">
      <div class="card-box">
        <h4 class="title border-bottom-1px">
          <span class="require">问题类型</span>
        </h4>
        <van-radio-group class="type-list" v-model="reportForm.questionType">
          <van-radio
            class="type-item-wrapper"
            :name="type.value"
            v-for="type in typeList"
            :key="type.value">
            {{type.name}}
          </van-radio>
        </van-radio-group>
      </div>
      <div class="card-box">
        <h4 class="title border-bottom-1px">
          <span class="require">问题描述</span>
        </h4>
        <van-field
          class="question-desc"
          v-model.trim="reportForm.questionDesc"
          clearable
          autosize
          show-word-limit
          rows="3"
          type="textarea"
          maxlength="300"
          placeholder="请告知问题发生的时间、地址及具体情况，以便我们快速处理，谢谢配合！"
        />
      </div>
      <div class="card-box">
        <h4 class="title border-bottom-1px">
          <span class="require">现场照片</span>
        </h4>
        <my-upload
          class="upload-wrapper"
          ref="scenePhotos"
          :files="reportForm.reportImgs"
          :progressSize="progressSize"
          :maxSize="fileMaxSize"
          :maxCount="fileMaxCount"
          :beforeRead="beforeRead"
          @oversize="oversize"
          @showImagePreview="handleShowImagePreview"
          @deleteFile="deleteFile"
          @uploadSuccess="uploadSuccess">
          <div class="upload-btn">
            <van-icon name="plus" />
          </div>
        </my-upload>
      </div>
      <div class="card-box address">
        <h4 class="title">
          <span class="require">现场地址</span>
        </h4>
        <van-field
          class="address-field"
          :border="false"
          v-model.trim="reportForm.questionAddr"
          clearable
          placeholder="请输入地址">
          <i class="iconfont icondingwei" slot="right-icon" @click="toSelectAddress"></i>
        </van-field>
      </div>
      <div class="submit-btn" @click="handleReport">提交</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import uploadMixin from '@/mixins/uploadMixin.js'
import { ENV } from '@/configs/config'
import { saveLoading } from '@/utils/utils'
import APIS from '@/api/index'
const { reportDisclose } = APIS

export default {
  name: 'wantReport',
  mixins: [uploadMixin],
  data () {
    return {
      reportForm: {
        questionType: 1, // 问题类型
        questionDesc: '', // 问题描述
        reportImgs: [], // 现场照片
        questionAddr: '', // 地址
        questionArea: '', // 街道
        latitude: 0, // 高德纬度
        longitude: 0 // 高德经度
      },
      typeList: [ // 问题类型列表
        {
          name: '市容环境',
          value: 1
        },
        {
          name: '宣传广告',
          value: 2
        },
        {
          name: '施工管理',
          value: 3
        },
        {
          name: '突发事件',
          value: 4
        },
        {
          name: '街面秩序',
          value: 5
        }
      ]
    }
  },
  beforeRouteLeave (to, from, next) {
    // 如果页面离开是去选择地址页面则缓存该页面否则不缓存
    if (to.name === 'selectAddress') {
      from.meta.cache = true
    } else {
      from.meta.cache = false
    }
    next()
  },
  computed: {
    ...mapState(['addressInfo'])
  },
  watch: {
    addressInfo (val) {
      let { address, street, latitude, longitude } = val
      this.reportForm = {
        ...this.reportForm,
        latitude,
        longitude,
        questionAddr: address,
        questionArea: street
      }
    }
  },
  mounted () {
    this.reportForm.questionType = this.typeList[0].value
  },
  methods: {
    ...mapMutations(['SET_ADDRESS_INFO']),
    // 预览图片
    handleShowImagePreview (startPosition) {
      let images = this.reportForm.reportImgs.map(img => img.url)
      this.$imagePreview({
        images,
        startPosition,
        closeOnPopstate: true
      })
    },
    // 删除文件
    deleteFile (index) {
      this.reportForm.reportImgs.splice(index, 1)
    },
    // 上传成功
    uploadSuccess (res) {
      this.reportForm.reportImgs.push({
        url: res.fileUrl,
        success: true
      })
    },
    // 提交上报
    handleReport () {
      let { questionType, questionDesc, reportImgs, questionAddr, questionArea, latitude, longitude } = this.reportForm

      let isUploadAll = this.$refs.scenePhotos.checkAllUploaded()

      if (!isUploadAll) {
        this.$toast('请等待图片上传完成')
      } else if (!questionDesc) {
        this.$toast('问题描述不能为空')
      } else if (reportImgs.length === 0) {
        this.$toast('至少上传一张照片')
      } else if (!questionAddr) {
        this.$toast('地址不能为空')
      } else {
        reportImgs = reportImgs.map(item => item.url).join(';')

        saveLoading({
          data: { questionType, questionDesc, reportImgs, questionAddr, questionArea, latitude, longitude, questionSource: ENV },
          api: reportDisclose,
          success: () => {
            this.resetData()
          }
        })
      }
    },
    // 跳转选择地址
    toSelectAddress () {
      this.$router.push('/selectAddress')
    },
    // 重置缓存的数据
    resetData () {
      this.reportForm = {
        questionType: 1, // 问题类型
        questionDesc: '', // 问题描述
        reportImgs: [], // 现场照片
        questionAddr: '', // 地址
        questionArea: '', // 街道
        latitude: 0, // 高德纬度
        longitude: 0 // 高德经度
      }
      this.$refs.scenePhotos && this.$refs.scenePhotos.clearFileList()
    }
  },
  beforeDestroy () {
    // 重置位置信息
    this.SET_ADDRESS_INFO({})
  },
  components: {
    'my-upload': () => import('@/components/MyUpload')
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .want-report-wrapper{
    .title{
      font-size: 32px;
      font-weight: 600;
      color: #333;
      padding-bottom: 28px;
    }
    .require{
      &::after{
        content: '*';
        color: #E94C4C;
      }
    }

    .type-list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &::after{
        content: ' ';
        display: block;
        width: 196px;
      }
      .type-item-wrapper{
        width: 196px;
        height: 68px;
        box-sizing: border-box;
        margin-top: 28px;
        font-size: 28px;
        text-align: center;
        color: #999;
        border:1px solid #ddd;
        @include flexCenter();
        ::v-deep .van-radio__label{
          color: #999;
        }
        &[aria-checked=true] {
          background: #0482FF;
          border: 0px;
          ::v-deep .van-radio__label{
            color: #fff;
          }
        }
      }
    }

    .question-desc{
      padding: 28px 0 0;
    }
    .upload-wrapper{
      margin-top: 28px;
      .upload-btn{
        width: 160px;
        height: 160px;
        background: #ffffff;
        border: 1px dashed #c8d1d6;
        border-radius: 8px;
        @include flexCenter();
        .van-icon-plus{
          color: #A8B8C0;
          font-size: 40px;
          font-weight: bold;
        }
      }
    }
    .card-box {
      margin-bottom: 20px;
      &.address{
        display: flex;
        .title{
          margin-right: 65px;
          padding: 0px;
          @include flexCenter();
        }
        .address-field{
          padding: 0px;
          flex: 1;
        }
        .icondingwei{
          color: #0566CE;
          font-size: 32px;
        }
      }
    }
    .submit-btn{
      width: 596px;
      height: 88px;
      background: linear-gradient(to left, #0dafff, #064dff);
      box-shadow: 0px 8px 13px 0px rgba(5,102,206,0.43);
      border-radius: 48px;
      @include flexCenter();
      margin: 33px auto;
      font-size: 36px;
      color: #fff;
    }
  }
</style>

<style lang="scss">
  .want-report-wrapper{
     .van-radio__icon{
      display: none;
    }
    .van-radio__label{
      margin: 0px;
    }
    [aria-checked=true] .van-radio__label{
      color: #fff;
    }
  }
</style>
